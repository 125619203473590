import React from 'react'
import { BlogBinkButton, useBlogBink } from '@ubo/blog-bink'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import NoPosts from 'components/elements/Misc/NoPosts'

// Layout
import Vacancy from './Vacancy'

// Interface
import { VacanciesProps } from '../VacanciesShell'

const Grid: React.FC<VacanciesProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          {blogBink.posts.length > 0 &&
            blogBink.posts.map((post) => {
              const { node }: any = post

              return (
                <div key={post.node.id} className="mt-5">
                  <Vacancy node={node} fields={fields} />
                </div>
              )
            })}
          {blogBink.posts.length === 0 && (
            <div className="mt-5 mt-lg-4">
              <NoPosts
                title="That ship has sailed..."
                body="Sorry! We couldn't find any vacancies that meet this criteria. Please try another category"
              />
            </div>
          )}
        </div>
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

export default Grid
