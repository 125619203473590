import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Filters from 'components/elements/Misc/Filters'
import Grid from './Layout/Grid'

// Interface
import { VacanciesProps } from 'components/flex/Vacancies/VacanciesShell'
import useLayout from 'hooks/useLayout'

const FilterWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.color.light};
  }
`

const Vacancies = styled.div`
  margin-top: -7.5rem;
`

const Overview: React.FC<VacanciesProps> = ({ fields }) => {
  const layout = useLayout()

  const { allWpVacancy, allWpVacancycategory } =
    useStaticQuery<GatsbyTypes.vacanciesQueryQuery>(graphql`
      query vacanciesQuery {
        allWpVacancy {
          edges {
            node {
              ...generalVacancyFragment
            }
          }
        }
        allWpVacancycategory {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `)

  const vacancies: unknown = allWpVacancy.edges.filter(
    ({ node }: any) => node.locale.locale === layout.locale
  )

  return (
    <section className="mb-5 pb-lg-5" id="Vacancies">
      <BlogBink
        posts={vacancies as BlogBinkPosts}
        categories={allWpVacancycategory.edges}
        categoryName="vacancycategories"
        id="vacancies-overview"
        limit={Number(fields.limit)}
      >
        <div className="py-5">
          <FilterWrapper className="py-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-11">
                  <Content content={fields.description} className="mb-5" />
                  <Filters />
                </div>
              </div>
            </div>
          </FilterWrapper>
          <Vacancies>
            <Grid fields={fields} />
          </Vacancies>
        </div>
      </BlogBink>
    </section>
  )
}

export default Overview
