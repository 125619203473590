import React from 'react'
import styled from 'styled-components'

import ParseContent from 'components/shared/ParseContent'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

// Images
import Location from 'img/location-transparent.inline.svg'
import Clock from 'img/clock-transparent.inline.svg'
import Bars from 'img/bars-transparent.inline.svg'

// Interface
import { VacanciesProps } from '../VacanciesShell'

const BlogGridPostExcerpt = styled(ParseContent)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;

  & p {
    line-height: 24px;
  }
`

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  font-weight: ${({ theme }) => theme.font.weight.light};
`

const Excerpt = styled.div`
  border-left: 3px solid ${({ theme }) => theme.color.primary};
`

const VacancyTitle = styled.h2`
  hyphens: auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
`

const Vacancy: React.FC<VacanciesProps> = ({ node, fields }) => (
  <Wrapper className="py-4 px-4 px-md-5">
    <div className="row">
      {console.log(node)}
      <div className="col-md-4">
        <VacancyTitle className="mb-4" style={{ hyphens: 'auto' }}>{node?.title}</VacancyTitle>
        <div>
          <div className="d-flex align-items-center mb-4">
            <Location />
            <span className="ms-2">{node?.recap.location}</span>
          </div>
          <div className="d-flex align-items-center mb-4">
            <Clock />
            <span className="ms-2">{node?.recap.hours}</span>
          </div>
          <div className="d-flex align-items-center mb-4">
            <Bars />
            <span className="ms-2">{node?.recap.level}</span>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <Excerpt className="h-100 d-flex flex-column justify-content-between ps-4 ps-md-5">
          <div className="pe-md-5">
            <BlogGridPostExcerpt
              content={node?.recap.excerpt}
              className="pe-md-5"
            />
          </div>
          <div className="mt-2">
            <ButtonPrimary to={node?.recap?.customLink ? node?.recap?.customLink?.url : node?.uri} target={node?.recap?.customLink ? '_blank' : '_self'}>
              {fields.readmoretext}
            </ButtonPrimary>
          </div>
        </Excerpt>
      </div>
    </div>
  </Wrapper>
)

export default Vacancy
